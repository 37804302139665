export const environment = {
  production: true,
  mapBoxAccessToken:'pk.eyJ1IjoiZXJjc2V5cGV0ZXIxIiwiYSI6ImNraXd4M2ZqdjFzYXcycXNjZDB4ejc2aWwifQ.TlJgNRdtGQizrgVtAYDFeg',
  apiServer: 'https://app.wman.com',
  //apiServer: 'https://wingman-staging.azurewebsites.net',
  //apiServer: 'https://sfs-test-build.azurewebsites.net',
  //apiServer: 'http://localhost:3000',
  mapbox: {
    mapStyle: 'mapbox://styles/mapbox/streets-v11'
  },
  socialLogin: {
    'facebook': '223045385190067',
    'google': '933900944635-18qc5t1c4jmeej7smcgvgjbo1qsfpe5h.apps.googleusercontent.com'
  },
  chatGPTApiKey: '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'
};
