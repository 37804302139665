import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AppService, AuthService, UserStateService, UtilsService } from '@services';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserProfile } from '../../../../../../shared/models';
import * as _ from 'lodash';
import { LocaleService } from '@core/services/misc/locale.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CheckoutComponent } from 'src/app/modules/payment/checkout/checkout.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  menuElements = [];
  modalRef;
  originalMenuElements = [
    {name: $localize `Personal info`, icon: 'fi-user', path: '/user/profile'},
    {name: $localize `Password & security`, providers: ['local'], icon: 'fi-lock', path: '/user/password'},
   /*  {name: $localize `Sights`, roles: ['creator'],  icon: 'fi-map-pins', path: '/tour-platform/sights'},
    {name: $localize `Tours`, roles: ['creator'],  icon: 'fi-map', path: '/tour-platform/tours'},
    {name: $localize `Reviews`, roles: ['creator'],  icon: 'fi-map', path: '/tour-platform/reviews'}, */
  /*   {name: $localize `Tours`, roles: ['user'],  icon: 'fi-map', path: '/user/myTours'},
    {name: $localize `Payments`, roles: ['user'],  icon: 'fi-credit-card', path: '/tour-platform/payments'}, */
/*     {name: $localize `Favourites`,  icon: 'fi-heart', path: '/user/favourites'}
 */  ];
  user;
  authSubscription;
  constructor(private userStateService: UserStateService, public authService: AuthService, private changeDetectorRef: ChangeDetectorRef, public localeService: LocaleService, private modalService: BsModalService, public utilsService: UtilsService){}


  /**
   * Initializes the component
   */
  ngOnInit(){
    this.authService.checkLogin()?.pipe(take(1)).subscribe();
    this.authSubscription = this.userStateService.user.subscribe((user)=>{
     if(user){
      this.user = user;
      this.menuElements = _.filter(this.originalMenuElements, (x: any)=>{
        return (x.roles ? x.roles.indexOf(this.user.roles[0]) !== -1 : true) && (x.providers ? x.providers.indexOf(this.user.provider) !== -1 : true)
      });
      this.changeDetectorRef.markForCheck();
     } else{
       this.user = null;
     }
    })
  }

  navigateToLanguage(language: string){
    window.location.href = window.location.href.replace(this.localeService.activeLocale, language);
  }

  /**
     * Opens the credit checkout modal
     */
   buyCreditsModal(){
    this.modalRef = this.modalService.show(CheckoutComponent, { class: 'modal-xl modal-dialog-centered'});
  }
  
  /**
   * Default component destructor
   */
  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }
}
