<form [formGroup]="tourForm" (change)="changedForm()" (ngSubmit)="saveOrSubmitTour(false)">
  <div class="modal-header">
    <div class="row d-flex align-items-center w-100">
      <div class="col-8 d-flex align-items-center">
        <img
          src="../../../../../assets/img/logo/icon.png"
          width="60"
          class="me-3"
          alt=""
        />
        <div *ngIf="mode === 'create'; else updateTourTitleBlock">
          <h3 class="mb-0" i18n>Create new tour</h3>
          <p class="text-muted mb-0" i18n>
            Create tour in seconds, earn for months
          </p>
        </div>
        <ng-template #updateTourTitleBlock>
          <div>
            <h3 class="mb-0" i18n>Update tour</h3>
            <p class="text-muted mb-0" i18n>
              Update tour in seconds, earn for months
            </p>
          </div>
        </ng-template>
      </div>
     <!--  <div class="col-4 text-end">
        <button class="btn btn-success btn-sm" *ngIf="mode === 'create'">
          Submit for validation
        </button>
      </div> -->
    </div>
  </div>
  <div class="modal-body px-5">
    <div class="row">
      <div class="col-6">
        <h5 i18n>Tour details</h5>

        <!-- Tour name -->
        <div class="mb-3">
          <label class="form-label" i18n>Name of the tour</label>
          <input
            type="text"
            class="form-control"
            placeholder="Name of the tour"
            formControlName="tourName"
            i18n-placeholder
          />
          <val-errors controlName="tourName" label="tourName">
            <ng-template class="text-danger" valError="required">
              <span class="text-danger sfs-text-08" i18n
                >Name is required
              </span>
            </ng-template>
          </val-errors>
          <ng-container>

            <div class="alert alert-danger mt-3 sfs-text-08" role="alert"  *ngFor="let error of getErrorByErrorName('tourName')">
              {{error}}
               </div>

        
          </ng-container>
        </div>
        <!-- End of Tour name-->
        <!-- Tour description -->
        <div class="mb-3">
          <label class="form-label" i18n>Description of the tour</label>
          <angular-editor [config]="editorConfig" formControlName="tourDescription" placeholder="Description of the tour" [placeholder]="'Description of the tour'" ></angular-editor>
          <val-errors controlName="tourDescription" label="tourDescription">
            <ng-template class="text-danger" valError="required">
              <span class="text-danger sfs-text-08" i18n
                >Description is required
              </span>
            </ng-template>
          </val-errors>
          <ng-container class="text-danger">

            <div class="alert alert-danger mt-3 sfs-text-08" role="alert"  *ngFor="let error of getErrorByErrorName('tourDescription')">
              {{error}}
               </div>

         
          
          </ng-container>
        </div>
        <!-- End of Tour description-->
        <!-- Tour language -->
        <div class="mb-3">
          <label class="form-label" i18n>Language</label>
          <ng-select
            [searchable]="false"
            [clearable]="false"
            (change)="changedForm()"
            formControlName="languages"
            placeholder="Select language"
            i18n-placeholder
          >
            <ng-option
              *ngFor="let language of configService.availableLanguages"
              [value]="language.code"
            >
              <span
                class="flag-icon flag-icon-{{
                  language.code === 'en' ? 'gb' : language.code
                }} me-1"
              ></span
              >{{ language.name }}
            </ng-option>
          </ng-select>
          <val-errors controlName="languages" label="languages">
            <ng-template class="text-danger" valError="required">
              <span class="text-danger sfs-text-08" i18n
                >Language is required
              </span>
            </ng-template>
          </val-errors>
        </div>
        <!-- End of Tour language-->
        <!-- City selector -->
        <div class="mb-3">
          <div class="d-flex ">
            <label class="form-label" i18n>City</label>
            <label class="form-label ms-auto sfs-hoverer" *ngIf="!pageSettings.newCityCreationMode else newCityModeActiveBlock" (click)="setAddNewCityMode(true)">+ Add new city to the list</label>
            <ng-template #newCityModeActiveBlock>
              <label class="form-label ms-auto sfs-hoverer" (click)="setAddNewCityMode(false)">Switch back to city list</label>

            </ng-template>
          </div>
          
          <ng-select
          *ngIf="!pageSettings.newCityCreationMode else newCityCreationModeBlock"
          placeholder="Select city"
          i18n-placeholder
          [clearable]="false"
          (change)="changedForm()"
          [searchable]="true"
          formControlName="city"
          [items]="filteredCities"
          bindLabel="name"
          bindValue="_id"
        >
          <ng-template ng-label-tmp let-item="item">
            {{ item?.name }} ({{item.country}})
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            {{ item?.name }} ({{item.country}})
          </ng-template>
        </ng-select>      
        
        <ng-template #newCityCreationModeBlock>
          <ng-select
          [items]="filteredCities"
          bindLabel="name"
          bindValue="_id"
          [searchable]="true"
          [clearable]="true"
          [loading]="loadingCities"
          placeholder="Select city"
          (search)="onSearch($event)"
          (change)="onCitySelect($event)"
        >
          <ng-template ng-label-tmp let-item="item">
            {{ item?.name }} ({{item?.country}})
          </ng-template>
       <!--    <ng-template ng-option-tmp let-item="item">
            {{ item?.name }} ({{item?.country}})
          </ng-template> -->
        </ng-select>
        
        </ng-template>
          <val-errors controlName="city" label="city">
            <ng-template class="text-danger" valError="required">
              <span class="text-danger sfs-text-08" i18n
                >City is required
              </span>
            </ng-template>
          </val-errors>
        </div>
        <!-- End of City selector-->

        <!-- Category -->
        <div class="mb-3">
          <label class="form-label" i18n>Category</label>
          <ng-select
            placeholder="Category of the tour"
            i18n-placeholder
            formControlName="categories"
            [searchable]="false"
            [clearable]="false"
            [multiple]="true"
          >
            <ng-option
              *ngFor="let category of configService.categories"
              [value]="category.code"
            >
              <i [class]="category.icon" class="me-1"></i> {{ category.name }}
            </ng-option>
          </ng-select>
          <val-errors controlName="categories" label="categories">
            <ng-template class="text-danger" valError="required">
              <span class="text-danger sfs-text-08" i18n
                >Categories are required
              </span>
            </ng-template>
          </val-errors>
        </div>
        <!-- End of Category -->
        <!-- Tour preferences -->
       <!--  <div class="mb-3">
          <label class="form-label" i18n>Tour preferences</label>
          <button class="btn btn-outline-secondary w-100" type="button" (click)="dropTourPreferencesModal()">
            <span *ngIf="!tourForm.getRawValue().preferences else preferencesSetBlock">Set tour preferences</span>
            <ng-template #preferencesSetBlock>
              <div class="d-flex align-items-center justify-content-center">
                <span i18n>Preferences set</span> <img src="../../../../../assets/img/icons/check.png" width="30" class="ms-1" alt="">
              </div>
             
            </ng-template>
          </button>
       </div> -->
         <!-- End of Tour preferences-->
        <!-- Images-->
        <div class="mb-5">
          <!-- Image form -->
          <form class="d-none">
            <div class="form-group">
              <input
                type="file"
                id="imageFileSelector"
                accept="image/png, image/jpeg"
                multiple
                (change)="uploadImages($event)"
              />
            </div>
          </form>
          <label class="form-label w-100 d-flex">
            <span>Images of the tour</span>
            <a
              href="javascript:void(0)"
              *ngIf="tourForm.getRawValue().media.length"
              class="ms-auto sfs-no-link sfs-text-08"
              (click)="openImageFileSelector('imageFileSelector')"
              ><i class="fi fi-plus me-1"></i>Add more</a
            >
          </label>
          <div *ngIf="tourForm.getRawValue().media.length; else noMediaBlock">
            <div class="row">
              <div class="col-12">
                <div
                  class="row g-2 g-md-3"
                  ngxDroppable
                  [model]="tourForm.get('media').value"
                >
                  <div
                    class="col-xl-4 col-sm-6"
                    *ngFor="
                      let item of tourForm.get('media').value;
                      let i = index
                    "
                    ngxDraggable
                    [model]="item"
                  >
                    <a
                      href="javascript:void(0)"
                      class="gallery-item no-hover rounded-2"
                    >
                      <div
                        class="sfs-upload-media-preview-card sahdow-sm"
                        [ngStyle]="{ background: 'url(' + item.url + ')' }"
                      ></div>
                      <button
                        class="btn btn-sm btn-light-primary btn-icon shadow-sm rounded-circle zindex-5"
                        (click)="removeGalleryItem(i)"
                      >
                        <i class="fi-trash"></i>
                      </button>
                      <div class="ribbon ribbon-top-right" *ngIf="i === 0">
                        <span i18n>Cover</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ng-template #noMediaBlock>
            <div role="alert" class="fade d-flex mb-4 alert alert-info show">
              <i class="fi-alert-circle me-2 me-sm-3"></i>
              <p class="fs-sm mb-1">
                The maximum photo size is 8 MB. Formats: jpeg, jpg, png. Put the
                main picture first.
              </p>
            </div>
            <div class="sfs-no-img-container">
              <div
                class="text-center text-muted sfs-text-08"
                (click)="openImageFileSelector()"
              >
                <div class="img-circle-container">
                  <i class="fa fa-camera fa-2x"></i>
                </div>
                <div class="mt-2">Click to upload (images up to 8 mb)</div>
              </div>
            </div>
          </ng-template>


          <val-errors controlName="media" label="media">
            <ng-template class="text-danger" valError="required">
              <span class="text-danger sfs-text-08" i18n
                >At least 1 image is required
              </span>
            </ng-template>
          </val-errors>

          <label class="form-label w-100 d-flex mt-3">
            <span>Price of the tour</span>
           
          </label>

          <div role="alert" class="fade d-flex mb-4 alert alert-info show">
            <i class="fi-credit-card me-2 me-sm-3"></i>
            <p class="fs-sm mb-1">
              <span *ngIf="pricing">The price of the tour: <span class="fw-bold">{{pricing.credits}} credits</span>. </span>
              The price of the tour is automatically calculated based on the number of sights selected. Ensure all sights are added to get an accurate price. Start addings sights to check the price.

            </p>
          </div>

          <ng-container class="text-danger">


            <div class="alert alert-danger mt-3 sfs-text-08" role="alert"  *ngFor="let error of getErrorByErrorName('coverImg')">
              {{error}}
               </div>

               
           
          </ng-container>
          <ng-container class="text-danger">

            <div class="alert alert-danger mt-3 sfs-text-08" role="alert"  *ngFor="let error of getErrorByErrorName('galleryImg')">
           {{error}}
            </div>

            

           
        
          </ng-container>
        </div>
        <!-- End of images -->
      </div>
      <div class="col-5 offset-1">
        <h5>Tour preview</h5>
        <app-tour-preview
          [tour]="tourForm.getRawValue()"
          [drawOnlyErrors]="true"
          [errors]="errors"
        ></app-tour-preview>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <!-- Sight list -->
        <app-sight-list
          [language]="tourForm.getRawValue().languages"
          [mode]="'adminEdit'"
          [addedSights]="tourForm.get('sights').value"
          (removeSightFromTour)="removeSightFromTour($event)"
          (addedSightToTour)="addedSightToTour($event)"
        ></app-sight-list>
        <!-- End of Sight list -->
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <app-error-list
          *ngIf="errors.length"
          class="w-100"
          [errors]="errors"
          [isScroll]="true"
        ></app-error-list>
      </div>
    </div>
    <div
      class="row mt-3"
      *ngIf="
        mode === 'edit' &&
        tour &&
        !this.authService.user.roles.includes('creator')
      "
    >
      <div class="col-12 col-md-6">
        <!-- Comment list -->
        <app-comment-list [tour]="tour"></app-comment-list>
        <!-- End of Comment list -->
      </div>
      <div class="col-12 col-md-6">
        <!-- Log list -->
        <app-log-list [tour]="tour"></app-log-list>
        <!-- End of Sight list -->
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex align-items-center justify-content-between">
    <button
      class="btn btn-outline-secondary btn-sm me-1"
      type="button"
      (click)="modalRef.hide()"
      i18n
    >
      Close
    </button>
    <div>
      <button
        class="btn btn-outline-secondary btn-sm me-1"
        type="button"
        *ngIf="mode !== 'create'"
        (click)="copyToClipboard()"
        i18n
      >
        <i class="fa fa-copy"></i> Copy tour link
      </button>
      <button
      class="btn btn-outline-secondary btn-sm me-1"
      type="button"
      (click)="saveOrSubmitTour(true)"
    >
      Save as draft
    </button>
      <button
        class="btn btn-success btn-sm"
        *ngIf="mode === 'create'; else editBtnBlock"
      >
        Submit for validation
      </button>
    </div>

    <ng-template #editBtnBlock>
      <button class="btn btn-primary btn-sm" i18n>Update</button>
    </ng-template>
  </div>
</form>
