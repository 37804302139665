import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable()
export class CityService {
  routePath = 'city';
  constructor(private httpService: HttpClient) {}

  get serverUrl(): string {
    return environment.apiServer;
  }

  get apiUrl(): string {
    return `${this.serverUrl}/api`;
  }

  getApiEndpoint(endpoint: string): string {
    return `${this.apiUrl}/${endpoint}`;
  }

  getCities(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getCities/`);
    return this.httpService.post<any>(url,{body});
  }

  getAllCities(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getAllCities/`);
    return this.httpService.post<any>(url,{body});
  }

  getNearestCity(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getNearestCity/`);
    return this.httpService.post<any>(url,body);
  }

  updateCity(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/updateCity/`);
    return this.httpService.post<any>(url,body);
  }

  getCitiesRaw(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getCitiesRaw/`);
    return this.httpService.post<any>(url,body);
  }

  addCity(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/addCity/`);
    return this.httpService.post<any>(url,body);
  }

  

}
