import { Location } from "@angular/common";
import { Component, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfigService } from "@core/services/config.service";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { AIService, ApiService, AuthService, ErrorService, NotyService, SightService, TourPlatformService, UserStateService } from "@services";
import { every, forEach, merge } from "lodash";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "app-create-sight",
  templateUrl: "./create-sight.component.html",
  styleUrls: ["./create-sight.component.css"],
})
export class CreateSightComponent implements OnInit {
  @Input() sight;
  @Input() type = "create";
  sightForm: FormGroup;
  cities: any[] = [];

  editorConfig: AngularEditorConfig = {
    editable: true,
    toolbarHiddenButtons: [
      [
        'fontName'
      ],
      [
        "insertImage",
        "insertVideo",
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
        'justifyLeft', // Hides "Align Left" button
      'justifyCenter', // Hides "Align Center" button
      'justifyRight', // Hides "Align Right" button
      'justifyFull' // Hides "Justify Text" button
      ],
    ]
  }

  characterLimit = 2000;
  aiState = 'idle';
  pageSettings = {
    activeVoiceMode: "upload",
    loaders: {
      uploadFile: false,
      uploadAudio: false,
    },
  };

  errors: any[] = [];

  voices: any[] = [];

  aiVoiceSettings = {
    textToGenerate : "",
    selectedVoice : null
  }

  @ViewChild('aiModal') aiModal: TemplateRef<any>;

  aiModalRef: BsModalRef

  constructor(
    public configService: ConfigService,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private notyService: NotyService,
    public modalRef: BsModalRef,
    private sightService: SightService,
    public userStateService: UserStateService,
    private location: Location,
    private router: Router,
    private tourPlatformService: TourPlatformService,
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private errorService: ErrorService,
    private modalService: BsModalService,
    private aiService: AIService,
    private sanitizer: DomSanitizer
  ) {
    this.sightForm = this.formBuilder.group({
      languages: [[], Validators.required],
      tourName: ["", Validators.required],
      tourDescription: ["", Validators.required],
      media: [[], [Validators.required, Validators.minLength(2)]],
      audios: [[], [Validators.required]],
      coordinates: [{
          type: "Point",
          coordinates: [-73.98572125, 40.75782025]
      }, Validators.required],
    });
  }

  getErrorByErrorName(key: string):any {
    const errors = this.errors.filter((err) => err.element.includes(key)).map((err) => err.error)
    if(errors.length){
      return errors;
    }
  }

  getErrorsForSight(id: string) {
    this.errors = [];
    this.errorService.getErrorsBySight({id}).subscribe((res) => {
      if(res && res.length) {
        this.errors = [...res];
      } else{
        this.errors = [];
      }
    })
  }

  

  setUploadMode(mode: string) {
    this.pageSettings.activeVoiceMode = mode;
    if(mode === 'upload'){
      this.sightForm.patchValue({
        audios: []
      });
    }
  }


  markerPositionUpdated($event) {
    console.log($event)
    this.sightForm.patchValue({
      coordinates: {
        type: "Point",
        coordinates: $event.marker,
      }
    });
  }

  /**
   * Uploads file to the server
   * @param event the event that triggers the upload of the file
   * @param param the file object
   */
  uploadAudio(event, param) {
    const files = (event.target as HTMLInputElement).files;
    let formData: any = new FormData();
    formData.append("filepond", files[0]);
    this.pageSettings.loaders.uploadFile = true;
    this.pageSettings.loaders.uploadAudio = true;
    this.apiService.uploadFile(formData).subscribe(
      (x) => {
        if (x.url) {
          this.sightForm.patchValue({
            //audios: [this.sightForm.getRawValue().audios, { value: x.url }],
            audios: [{ value: x.url }]
          });
        }
        this.pageSettings.loaders.uploadFile = false;
        this.pageSettings.loaders.uploadAudio = false;
        event.target.value = "";
      },
      () => {
        this.pageSettings.loaders.uploadFile = false;
        this.pageSettings.loaders.uploadAudio = false;
        this.notyService.dropNoty(
          "error",
          $localize`The requested file is too large or damaged. Please try again.`
        );
        event.target.value = "";
      }
    );
  }

  removeSightAudio(index) {
    this.sightForm.patchValue({
      audios: [
        ...this.sightForm.getRawValue().audios.slice(0, index),
        ...this.sightForm.getRawValue().audios.slice(index + 1),
      ],
    });
  }
  removeGalleryItem(index) {
    this.sightForm.patchValue({
      media: [
        ...this.sightForm.getRawValue().media.slice(0, index),
        ...this.sightForm.getRawValue().media.slice(index + 1),
      ],
    });
  }

  /**
   * Opens the file selector for image upload
   */
  openFileSelector(selector) {
    document.getElementById(selector).click();
  }

  /**
   * Fires the upload of the image array selected via image input control
   */
  uploadImages(event) {
    const files = (event.target as HTMLInputElement).files;
    forEach(files, (x) => {
      this.uploadImage(x);
    });
    event.target.value = "";
  }

  /**
   * Uploads an image to the server
   * @param {obj} x the image object you want to upload
   */
  uploadImage(x) {
    var formData: any = new FormData();
    formData.append("filepond", x);
    this.pageSettings.loaders.uploadFile = true;
    this.apiService.uploadFile(formData).subscribe(
      (x) => {
        if (x.url) {
          this.sightForm.patchValue({
            media: [...this.sightForm.getRawValue().media, { url: x.url }],
          });
        }
        this.pageSettings.loaders.uploadFile = false;
      },
      () => {
        this.pageSettings.loaders.uploadFile = false;
        this.notyService.dropNoty(
          "error",
          $localize`The requested file is too large or damaged. Please try again.`
        );
      }
    );
  }

  copyToClipboard(){
      if (navigator && navigator.clipboard) {
        const baseUrl = window.location.origin;
        const path = this.location.prepareExternalUrl(this.router.url.split('?')[0]);
        let queryParams = { ...this.activatedRoute.snapshot.queryParams };
        queryParams['sId'] = this.sight._id;
        let searchParams = new URLSearchParams(queryParams);
        const finalUrl = `${baseUrl}${path}?${searchParams.toString()}`;
        this.notyService.dropNoty('success', $localize`Link copied to clipboard`);
        return navigator.clipboard.writeText(finalUrl);
    }
  }

  submitSight() {
    if(this.sightForm.valid){
      this.sightForm.patchValue({
        tourName: [{
          value: this.sightForm.getRawValue().tourName,
          language: this.sightForm.getRawValue().languages
        }],
      });
      this.sightForm.patchValue({
        tourDescription: [{
          value: this.sightForm.getRawValue().tourDescription,
          language: this.sightForm.getRawValue().languages
        }],
      });
      this.modalRef.hide();
      if(this.type === 'create'){
        this.sightService.createSight(this.sightForm.getRawValue()).subscribe(()=>{
          this.notyService.dropNoty('success', $localize`Sight created successfully. Please refresh the page to see the changes.`);
          this.tourPlatformService.sightListFilterEvent.next(merge(this.tourPlatformService.sightListFilterEvent.value, {forceRefresh: true}));
        });
      } else{
        this.sightService.updateSight(this.sightForm.getRawValue()).subscribe(()=>{
          this.notyService.dropNoty('success', $localize`Sight updated successfully`);
          this.tourPlatformService.sightListFilterEvent.next(merge(this.tourPlatformService.sightListFilterEvent.value, {forceRefresh: true}));
        });
      }
      
     
    } else{
      this.notyService.dropNoty('error', $localize`Please fill all the required fields`);
    }
  }

  submitSight1(isDraft: boolean = false) {
    const sightFormValue = this.sightForm.getRawValue();

    if (isDraft && !sightFormValue.tourName) {
      this.notyService.dropNoty('error', $localize`Please fill all the required fields`);
      return;
    }

    if (!isDraft && !this.sightForm.valid) {
      this.notyService.dropNoty('error', $localize`Please fill all the required fields`);
      return;
    }

    if (
      this.sightForm.getRawValue().tourName?.length > 0
    ) {
      this.modalRef.hide();

      this.sightForm.patchValue({
        tourName: [{
          value: this.sightForm.getRawValue().tourName,
          language: this.sightForm.getRawValue().languages.length > 0 ? this.sightForm.getRawValue().languages : ''
        }],
      });
      this.sightForm.patchValue({
        tourDescription: [{
          value: this.sightForm.getRawValue().tourDescription,
          language: this.sightForm.getRawValue().languages.length > 0 ? this.sightForm.getRawValue().languages : ''
        }],
      });

    const sightObj = this.sightForm.getRawValue();

    if (this.type === 'create') {

      if (isDraft) {
        sightObj.isDraft = true;
      }

      this.createOrUpdateSight(sightObj, isDraft);
    } else {
      sightObj._id = this.sight._id;

      sightObj.isDraft = !this.sightForm.valid;

      this.createOrUpdateSight(sightObj, isDraft);
    }

    } else {
      console.log(this.sightForm.getRawValue())
      this.notyService.dropNoty('error', $localize`Please select the same language for all fields`);
    }



  }

  createOrUpdateSight(tourObj: any, isDraft: boolean) {
    const serviceCall = this.type === 'create'
      ? this.sightService.createSight(tourObj)
      : this.sightService.updateSight(tourObj);
  
    serviceCall.subscribe(() => {
      const successMessage = isDraft
        ? $localize`Draft saved successfully!`
        : $localize`Sight created successfully!`;
  
      this.notyService.dropNoty('success', successMessage);
      this.tourPlatformService.tourListFilterEvent.next(
        merge(this.tourPlatformService.tourListFilterEvent.value, { forceRefresh: true })
      );
    }, () => {
      const errorMessage = isDraft
        ? $localize`Failed to save the draft. Please try again.`
        : $localize`Failed to create the sight. Please try again.`;
  
      this.notyService.dropNoty('error', errorMessage);
    });
  }

  generateVoice() {
    this.aiModalRef.hide();
    this.aiState = 'loading';
    this.aiService.generateVoice({
      voice: this.aiVoiceSettings.selectedVoice.name,
      text: this.aiVoiceSettings.textToGenerate
    }).subscribe((res) => {
        if (res.url) {
          this.sightForm.patchValue({
            //audios: [this.sightForm.getRawValue().audios, { value: x.url }],
            audios: [{ value: res.url }]
          });
        }
        this.pageSettings.loaders.uploadFile = false;
        this.pageSettings.loaders.uploadAudio = false;
        this.aiState = 'idle';
       
      //this.uploadAudio({target:{ files: [res]}}, null)
    })
  }

  openAIModal(){
    this.aiState = 'idle';
    this.aiModalRef = this.modalService.show(this.aiModal, {class: 'modal modal-dialog-centered'});
    this.aiService.getAIVoices().subscribe((res) => {
      this.voices = res?.voices.filter(x => !x.name.includes('Guttmann'));
      console.log(this.voices)
      this.aiVoiceSettings.selectedVoice =  this.voices[0];

      const tempElement = document.createElement("div");
tempElement.innerHTML = this.sightForm.value.tourDescription;
const plainText = tempElement.textContent || tempElement.innerText || "";

// Assign the cleaned text while preserving special characters
this.aiVoiceSettings.textToGenerate = plainText;

    })

  this.aiModalRef.onHidden.subscribe(()=> {
    if(this.aiState === 'loading'){
      this.pageSettings.loaders.uploadFile = true;
      this.pageSettings.loaders.uploadAudio = true;
    } else{
      this.pageSettings.loaders.uploadFile = false;
      this.pageSettings.loaders.uploadAudio = false;
      this.pageSettings.activeVoiceMode = 'upload';
    }
   
  })
  }

  refreshAudio() {
    (document.getElementById("exampleVoice") as any)?.load(); 
  }

  aiDescriptionChanged(ev) {
    this.sightForm.patchValue({tourDescription:this.aiVoiceSettings.textToGenerate })
  }
  
  isAIBtnAvailable(): boolean {
    const sightFormValue = this.sightForm.getRawValue()
    return sightFormValue.tourDescription &&  sightFormValue && sightFormValue.languages && (sightFormValue.languages.includes('en') || sightFormValue.languages.includes('de') )
  }



  ngOnInit() {
    if(this.sight){
      console.log(this.sight)
      this.sightForm.addControl('_id', this.formBuilder.control(''));
      this.sightForm.patchValue(this.sight);
     
      this.sightForm.patchValue({
        _id: this.sight._id,
        tourName: this.sight.name,
        tourDescription: this.sight.description,
        languages: this.sight.languages[0]
      })
      this.router.navigate([], {
        queryParams: {
          'sId': null,
        },
        queryParamsHandling: 'merge'
      });
      this.getErrorsForSight(this.sight._id)
      
   /*    setTimeout(()=>{
        this.location.replaceState(this.router.url, 'sId='+this.sight._id);
      },1000) */
       
    }
  }
}
