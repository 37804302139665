import { Injectable, Optional, SkipSelf } from '@angular/core';
import * as _ from 'lodash';
import { take } from 'rxjs/operators';
import { ApiService } from './requests/api.service';

@Injectable({
    providedIn: 'root' 
})
export class ConfigService {
    availableLanguages = [{
        name: 'English', code: 'en'
    }, {
        name: 'Hungarian', code: 'hu'
    }, {
        name: 'German', code: 'de'
    }];
    privacyPolicyURL;
    termsOfServiceURL;
    priceRange = [0,100];
    cities = [];
    categories = []
    packages;

    constructor(private apiService: ApiService) {
        
    }

    getConfig(){
        this.apiService.getConfig().pipe(take(1)).subscribe((x: any)=>{ 
            console.log(x)
            this.cities = x.cities;
            this.categories = x.categories;
            this.priceRange = x.priceRange;
            this.availableLanguages = x.languages;
            this.packages = x.packages;
            this.privacyPolicyURL = x.privacyPolicyURL;
            this.termsOfServiceURL = x.termsOfServiceURL;
          })
    }

    convertLanguage(code){
        if(this.availableLanguages.length){
            const lang = _.find(this.availableLanguages, (x) => {
                return x.code === code
              });
              return lang ? lang.name : 'en';
        } else{
            return false;
        } 
    }

    


}
