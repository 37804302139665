import { ToastrService } from 'ngx-toastr';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserProfileModel } from '../../../../../shared/models/user-profile.model';
import { ApiService, AuthService, NotyService } from '@services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { take } from 'rxjs/operators';
import { LocaleService } from '@core/services/misc/locale.service';
import { ConfigService } from '@core/services/config.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  userProfileForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    isTourGuide: new FormControl(true),
    socialURL: new FormControl('')  // No validators initially
  });

  pageSettings = {
    loaders: {
      register: false
    }
  };

  optIn: boolean = false;

  constructor(
    private apiService: ApiService,
    public configService: ConfigService,
    private router: Router,
    private authService: AuthService,
    private notyService: NotyService,
    private localeService: LocaleService
  ) {}

  ngOnInit() {
    this.checkLoggedInStates();

    // Ensure validators are dynamically applied on changes
    this.userProfileForm.get('isTourGuide').valueChanges.subscribe(isTourGuide => {
      const socialURLControl = this.userProfileForm.get('socialURL');
      if (isTourGuide) {
        socialURLControl.setValidators([Validators.required, this.socialUrlValidator]);
      } else {
        socialURLControl.clearValidators();
      }
      socialURLControl.updateValueAndValidity();
    });
  }

  async checkLoggedInStates() {
    const isLoggedIn = await this.authService.isLoggedInAsync;
    if (isLoggedIn) {
      this.router.navigateByUrl('/');
    }
  }

  // Custom URL Validator for Instagram and TikTok
  socialUrlValidator(control: FormControl): { [key: string]: any } | null {
    const value = control.value.trim();
    const pattern = /^(https?:\/\/)?(www\.)?(instagram\.com\/[a-zA-Z0-9._]{1,30}\/?|tiktok\.com\/@?[a-zA-Z0-9._]{2,24}\/?)$/;
  
    if (!value) {
      return { required: true };
    }

    return pattern.test(value) ? null : { invalidSocialURL: true };
  }

  setUserType(value: boolean) {
    this.userProfileForm.controls['isTourGuide'].setValue(value);
  }

  login() {
    this.authService.login(
      this.userProfileForm.controls['email'].value,
      this.userProfileForm.controls['password'].value
    ).pipe(take(1)).subscribe(() => {
      this.router.navigateByUrl('/tour-platform');
    });
  }

  onRegisterClick(): void {
    this.pageSettings.loaders.register = true;

    if (this.userProfileForm.invalid) {
      this.notyService.dropNoty('error', $localize`Please fill in all required fields.`);
      this.pageSettings.loaders.register = false;
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const roleParam = urlParams.get('type');

    this.apiService.registerTourGuide(_.merge(
      this.userProfileForm.getRawValue(),
      roleParam === 'creator' 
        ? { roles: ['creator'] } 
        : { locale: this.localeService.activeLocaleObject?.shortHand || 'en' }
    )).subscribe((x: any) => {
      if (x && x.success) {
        this.login();
      } else {
        this.notyService.dropNoty('error', $localize `The email address is already in use.`);
      }
      this.pageSettings.loaders.register = false;
    }, () => {
      this.pageSettings.loaders.register = false;
    });
  }
}
