import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigService } from '@core/services/config.service';
import { ApiService, AuthService, CityService, ErrorService, NotyService, TourFormService, TourPlatformService, TourService, UtilsService } from '@services';
import { Validator } from 'class-validator';
import { debounceTime, switchMap, take } from 'rxjs/operators';
import { forEach, merge, map, find, every } from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { TourPreferencesComponent } from './tour-preferences/tour-preferences.component';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { createApi } from 'unsplash-js';
import * as nodeFetch from 'node-fetch'
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-create-tour',
  templateUrl: './create-tour.component.html',
  styleUrls: ['./create-tour.component.css']
})
export class CreateTourComponent implements OnInit {
  @Input() tour;
  tourForm: FormGroup;
  cities: any[] = [];
  pricing: any;
  @Input() mode = 'create';
  pageSettings = {
    newCityCreationMode: false,
    loaders: {
      uploadFile: false
    }
  };
  filteredCities: any[] = [];
  newCity: any = {};
  loadingCities = false;

  searchCityImage: string;

  editorConfig: AngularEditorConfig = {
    editable: true,
    toolbarHiddenButtons: [
      [
        'fontName'
      ],
      [
        "insertImage",
        "insertVideo",
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
        'justifyLeft', // Hides "Align Left" button
        'justifyCenter', // Hides "Align Center" button
        'justifyRight', // Hides "Align Right" button
        'justifyFull' // Hides "Justify Text" button
      ],
    ]
  }

  unsplash = createApi({
    accessKey: "XwTCSYYoGhKNR-yKI_iQDN9yST3heBrR3kAAeeF9neU",
    fetch: nodeFetch.default as unknown as typeof fetch,
  });
  
  private readonly MAPBOX_API_URL = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';

  errors: any[] = [];
  constructor(public configService: ConfigService, private formBuilder: FormBuilder, private cityService: CityService, private apiService: ApiService, private notyService: NotyService, public modalRef: BsModalRef, private tourFormService: TourFormService, private tourService: TourService, private tourPlatformService: TourPlatformService, private location: Location, private router: Router, private activatedRoute: ActivatedRoute, public authService: AuthService, private errorService: ErrorService, private utilsService: UtilsService, private modalService: BsModalService, private http: HttpClient) {
    this.tourForm = this.formBuilder.group({
      languages: ['', Validators.required],
      city: ['', Validators.required],
      categories: [[], Validators.required],
      tourName: ['', Validators.required],
      tourDescription: ['', Validators.required],
      media: [[], Validators.required],
      sights: [[], Validators.required],
      preferences: [null]
    });
  }

  setAddNewCityMode(mode: boolean){ 
    this.pageSettings.newCityCreationMode= mode;
    if(mode){
      this.filteredCities = [];
    } else{
      this.filteredCities = [...this.cities];
      this.newCity = {};
      this.tourForm.patchValue({
        city: this.cities[0]._id
      })
    }
  }

  getErrorByErrorName(key: string):any {
    const errors = this.errors.filter((err) => err.element.includes(key)).map((err) => err.error)
    if(errors.length){
      return errors;
    }
  }

  changedForm(){
    this.tourFormService.tourEdited = this.tourForm.getRawValue();
  }

  onCitySelect(selectedCity: any): void {
    console.log(selectedCity)
    if (selectedCity) {
      this.tourForm.patchValue({
        city: selectedCity._id, // Update the form with the selected city ID
      });
      this.newCity = {
        name: selectedCity.name,
        cover: selectedCity.cover,
        country: selectedCity.country,
        coordinates: selectedCity.coordinates,
      };
    }
  }

  async onSearch(event: any): Promise<void> {
    const inputValue = event.term;
    // Fetch matching locations from Mapbox API
    try {
      const response = await this.checkCity(inputValue);
      if (response.features && response.features.length > 0) {
        console.log(response.features)
        const mapboxLocations = response.features.map((feature: any) => ({
          name: feature.place_name,
          coordinates: feature.center,
          country: this.getCountryFromContext(feature.context),
          _id: feature.id, // Use the Mapbox feature ID as a unique identifier
        }));
  
        // Update the filteredCities to display autocomplete results
        this.filteredCities = mapboxLocations;
      } else {
        this.filteredCities = []; // Clear suggestions if no match found
      }
    } catch (error) {
      console.error('Error fetching cities:', error);
      this.filteredCities = []; // Clear suggestions on error
    }
  }
  

  getCountryFromContext(context: any[]): string {
    if(!context){
      return '';
    } else{
      const countryContext = context.find((c: any) => c.id.startsWith('country'));
      return countryContext ? countryContext.text : '';
    }
  }
  
  getCityImage(cityName: string): string {
    // Replace this with logic to fetch a city-specific image if needed
    return 'https://images.unsplash.com/photo-1732613942657-61684c51eb55?q=80&w=3468&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';
  }

  async checkCity(query: string): Promise<any> {
    this.loadingCities = true;
    const url = `${this.MAPBOX_API_URL}${encodeURIComponent(query)}.json?access_token=${environment.mapBoxAccessToken}&types=place`;
  
    try {
      const response = await this.http.get(url).toPromise();
  
      if (response && response['features']) {
        this.loadingCities = false;
        return response; // Return the response to the caller
      } else {
        this.loadingCities = false;
        return { features: [] }; // Return an empty features array if no features exist
      }
    } catch (error) {
      console.error('Error fetching city data:', error);
      this.loadingCities = false;
      return { features: [] }; // Return an empty features array on error
    }
  }
  
  
  dropTourPreferencesModal(){
    console.log(this.tourForm.getRawValue());
    this.modalRef = this.modalService.show(TourPreferencesComponent, {
        initialState: {
          tour: this.tourForm.getRawValue()
        },
        class: 'modal-lg modal-dialog-centered',
      });
      this.modalRef.onHidden.pipe(take(1)).subscribe((reason: string | any) => {
        if (typeof reason !== null) {
          this.notyService.dropNoty('success', $localize `Tour preferences updated`);
          this.tourForm.patchValue({
            preferences: reason
          });
          // You can handle the reason here
        } else {
          console.log('Modal closed');
        }
      });
  }

  removeGalleryItem(index){
    this.tourForm.patchValue({
      media: [...this.tourForm.getRawValue().media.slice(0, index), ...this.tourForm.getRawValue().media.slice(index + 1)]
    });
  }

  /**
   * Gets available cities
   */
  getCities() {
    this.cityService.getAllCities({}).pipe(take(1)).subscribe((x) => {
      this.cities = x;
      this.filteredCities = [...this.cities]; // Populate filteredCities with the cities data
  
      if (!this.tour) {
        this.tourForm.patchValue({
          city: this.cities[0]._id
        });
      }


  
      this.tourForm.patchValue({
        languages: this.configService.availableLanguages[0].code
      });

    })
  }

   /**
* Opens the file selector for image upload
*/
openImageFileSelector() {
  document.getElementById('imageFileSelector').click();
}


/**
* Fires the upload of the image array selected via image input control
*/
uploadImages(event) {
  const files = (event.target as HTMLInputElement).files;
  forEach(files, (x) => {
    this.uploadImage(x);
  })
  event.target.value = '';
}

  /**
* Uploads an image to the server
* @param {obj} x the image object you want to upload
*/
uploadImage(x) {
  var formData: any = new FormData();
  formData.append("filepond", x);
  this.pageSettings.loaders.uploadFile = true;
  this.apiService.uploadFile(formData).subscribe((x) => {
    if (x.url) {
      this.tourForm.patchValue({
        media: [...this.tourForm.getRawValue().media, { url: x.url }]
      });
    }
    this.pageSettings.loaders.uploadFile = false;
  }, () => {
    this.pageSettings.loaders.uploadFile = false;
    this.notyService.dropNoty('error', $localize `The requested file is too large or damaged. Please try again.`);
  })
}

getErrorsForTour(id: string) {
  this.errorService.getErrorsByTourId({id}).subscribe((res) => {
    if (res && res.length) {
      this.errors = [...res]
    }
  })
}

saveOrSubmitTour(isDraft: boolean = false) {
  const tourData = this.tourForm.getRawValue();

  if (isDraft && !tourData.tourName) {
    this.notyService.dropNoty('error', $localize`Please fill the name of the tour`);
    return;
  }

  if (!isDraft && !this.tourForm.valid) {
    this.notyService.dropNoty('error', $localize`Please fill all the required fields`);
    return;
  }

  if (
    every(tourData.sights, (s) => {
      return s.languages?.[0] === tourData.languages;
    })
  ) {
    this.modalRef.hide();

    this.tourForm.patchValue({
      languages: [tourData.languages],
    });

    const tempElement = document.createElement("div");
    tempElement.innerHTML = this.tourForm.value.tourDescription;
    const plainText = tempElement.textContent || tempElement.innerText || "";

    const tourObj = merge(tourData, {
      sights: map(tourData.sights, (s) => s._id),
      tourName: [
        {
          language: tourData.languages,
          value: tourData.tourName,
        },
      ],
      tourDescription: [
        {
          language: tourData.languages,
          value: plainText,
        },
      ],
    });

    if (this.mode === 'create') {
      if (this.newCity?.name?.length > 0) {
        // Logic for fetching and processing the city image
        this.unsplash.search.getPhotos({
          query: this.newCity.name,
          perPage: 1,
        }).then(async (result) => {
          const searchCityImage = result.response?.results?.length
            ? result.response.results[0].urls.regular
            : 'https://images.unsplash.com/photo-1732613942657-61684c51eb55?q=80&w=3468&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'; // Fallback image

          await fetch(searchCityImage)
            .then((response) => response.blob())
            .then((blob) => {
              const file = new File([blob], 'city-image.jpg', { type: blob.type });

              const formData = new FormData();
              formData.append('filepond', file);

              this.apiService.uploadFileAndProcess(formData).subscribe((response) => {
                this.newCity.cover = response.url;

                this.notyService.dropNoty('success', $localize `Tour has been uploaded. The uploading process may take a few minutes. You will be notified when it is done.`);

                this.cityService.addCity(this.newCity).subscribe((city) => {
                  tourObj.city = city.city;

                  if (isDraft) {
                    tourObj.isDraft = true;
                  }

                  this.createOrUpdateTour(tourObj, isDraft);
                }, () => {
                  this.notyService.dropNoty('error', $localize`Failed to add the city. Please try again.`);
                });
              });
            })
            .catch((error) => {
              console.error('Error fetching or uploading image:', error);
            });
        });
      } else {
        // No new city, directly create or update the tour
        if (isDraft) {
          tourObj.isDraft = true;
        }
        this.createOrUpdateTour(tourObj, isDraft);
      }
    } else {
      // Update mode logic
      tourObj._id = this.tour._id;
      tourObj.isDraft = !this.tourForm.valid;

      this.createOrUpdateTour(tourObj, isDraft);
    }
  } else {
    this.notyService.dropNoty('error', $localize`The language of the sight is different than the language of the tour`);
  }
}

createOrUpdateTour(tourObj: any, isDraft: boolean) {
  const serviceCall = this.mode === 'create'
    ? this.tourService.createTour(tourObj)
    : this.tourService.modifyTour(tourObj);

  serviceCall.subscribe(() => {
    const successMessage = isDraft
      ? $localize`Draft saved successfully!`
      : $localize`Tour created successfully and is now in validation process. You will be notified when it is validated!`;

    this.notyService.dropNoty('success', successMessage);
    this.tourPlatformService.tourListFilterEvent.next(
      merge(this.tourPlatformService.tourListFilterEvent.value, { forceRefresh: true })
    );
  }, () => {
    const errorMessage = isDraft
      ? $localize`Failed to save the draft. Please try again.`
      : $localize`Failed to create the tour. Please try again.`;

    this.notyService.dropNoty('error', errorMessage);
  });
}


getPricing(){
  this.tourService.getPricing({
    numberOfSights: this.tourForm.getRawValue().sights.length
  }).subscribe((pricing)=>{
   console.log(pricing);
   this.pricing = pricing;
  });
}

  addedSightToTour($event){
    if(this.tourForm.getRawValue().languages === $event.languages[0]){
      if(find(this.tourForm.getRawValue().sights, (s: any)=>{
        return s._id === $event._id;
      })){
        this.tourForm.patchValue({
          sights: map(this.tourForm.getRawValue().sights, (s: any)=>{
            if(s._id === $event._id){
              s.isAdded = true;
              return s;
            } else{
              return s;
            }
          }) 
        });
      } else{
        this.tourForm.patchValue({
          sights: [...this.tourForm.getRawValue().sights, $event]
        });
      }
  
        this.getPricing();
      
      this.changedForm();
      this.notyService.dropNoty('success', $localize `Sight added to tour`)
    } else{
      this.notyService.dropNoty('error', $localize`The language of the sight is different then the language of the tour`);
    }
   
    
  }

  removeSightFromTour($event){
    this.tourForm.patchValue({
      sights: [...this.tourForm.getRawValue().sights.filter((x) => { return x._id !== $event._id })]
    });
    this.changedForm();
    this.notyService.dropNoty('info', $localize `Sight removed from tour`)
  }

  getTourById(){
      this.tourService.getTour({id: this.tour._id}).subscribe((tour) => {
        console.log(tour)
        const substringLength = 50;
        tour.sights = map(tour.sights, (sight) => {
          return merge(sight, {
            name: sight.tourName?.value?.length > substringLength ? sight.tourName?.value.substring(0, substringLength) + '...' : sight.tourName?.value,
            description: sight.tourDescription?.value?.length > substringLength ? sight.tourDescription?.value.substring(0, substringLength) + '...' : sight.tourDescription?.value
          });
        });
        this.tourForm.patchValue({tourName: tour.tourName.value});
        this.tourForm.patchValue({tourDescription: tour.tourDescription.value});
        this.tourForm.patchValue({media: tour.media});
        this.tourForm.patchValue({sights: tour.sights});
        this.tourForm.patchValue({city: tour.city._id});
        this.tourForm.patchValue({categories: tour.categories});
        this.tourForm.patchValue({preferences: tour.preferences});
        console.log(tour.languages[0])
        this.getPricing();
        this.tourForm.patchValue({languages: tour.languages[0]});
        this.getErrorsForTour(tour._id)
      });
  }

  copyToClipboard(){
    if(navigator && navigator.clipboard){
      const baseUrl = window.location.origin;
      const path = this.location.prepareExternalUrl(this.router.url.split('?')[0]);
      let queryParams = new URLSearchParams(this.activatedRoute.snapshot.queryParams);
      queryParams.set('tId', this.tour._id);
      let queryString = queryParams.toString();
      this.notyService.dropNoty('success', $localize `Link copied to clipboard`);
      return navigator.clipboard.writeText(queryString ? `${baseUrl}${path}?${queryString}` : `${baseUrl}${path}`);
    
    }
  }

  ngOnInit() {
    this.getCities();
    if(this.tour){
      this.getTourById();
    }
  }

}
