import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Route, RouterModule } from '@angular/router';
import { NgtUniversalModule } from '@ng-toolkit/universal';
import { AuthGuardService, AuthService } from '@services';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './components/auth/auth.module';
import { AdminModule } from './components/admin/admin.module';
import { CommonPagesModule } from './components/common/common.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageNotFoundComponent } from './components/common/page-not-found/page-not-found.component';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { SightModalModule } from './modules/sight-modal/sight-modal.module';
import { CheckoutModule } from './modules/payment/checkout/checkout.module';
import { BookModule } from './modules/payment/book/book.module';
import { HomeModule } from '../app/components/home/home.module';

import { TourModalModule } from './modules/tour-modal/tour-modal.module';
import { TourMapModule } from './modules/tour-map/tour-map.module';
import { ToursModule } from '../app/components/tours/tours.module';

import { TourListModule } from './modules/tour-list/tour-list.module';
import { DetailedTourComponent } from './components/detailed-tour/detailed-tour.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { ToursComponent } from '../app/components/tours/tours.component';
  
import { LightgalleryModule } from 'lightgallery/angular/11';
import { SightListModule } from './modules/sight-list/sight-list.module';
import { MinifiedSightListModule } from './modules/minified-sight-list/minified-sight-list.module';

import { LoginModalModule } from '../app/modules/auth/login-modal/login-modal.module';
import { SimilarToursModule } from '../app/modules/similar-tours/similar-tours.module';

import { TermsModule } from './components/terms/terms.module';
import { SocialLoginModule } from 'src/app/social-login/social-login.module';
import {TermsComponent} from './components/terms/terms.component';
import { RegisterComponent } from './components/register/register.component';
import { TourSightseeingModule } from './modules/tour-sightseeing/tour-sightseeing.module';
import { ErrorLogModule } from './modules/error-log/error-log.module';

import { LoaderModule } from './modules/loader/loader.module';

import { ErrorHandler } from '@angular/core';
import { RaygunErrorHandler } from './app.raygun.setup';
import { NgSelectModule } from '@ng-select/ng-select';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NoDataBlockModule } from './modules/no-data-block/no-data-block.module';
import { ReviewsBlockModule } from './modules/reviews-block/reviews-block.module';

import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { LogListModule } from './components/tour-platform/components/log-list/log-list.module';
const routes: Route[] = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  {
    path: 'tour/:id',
    component: DetailedTourComponent
  },
  {
    path: 'tour/:id/:locale',
    component: DetailedTourComponent
  },
  {
    path: 'tours',
    component: ToursComponent
  }, 
  { path: 'user', loadChildren: () => import('./components/auth/auth.module').then(m => m.AuthModule) },
  { path: 'edit', loadChildren: () => import('./components/edit/edit.module').then(m => m.EditModule) },
  { path: 'admin', loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule) },
  { path: 'tour-platform', loadChildren: () => import('./components/tour-platform/tour-platform.module').then(m => m.TourPlatformModule) },
  { path: 'payment', loadChildren: () => import('./components/payment/payment.module').then(m => m.PaymentModule) },
  {
    path: "**",
    component: PageNotFoundComponent
  }
];

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    DetailedTourComponent
  ],
  imports: [
    HomeModule,
    BrowserModule,
    TermsModule,
    BrowserAnimationsModule,
    CommonPagesModule,
    NgtUniversalModule,
    SharedModule,
    CoreModule,
    LoadingBarModule,
    CheckoutModule,
    BookModule,
    RouterModule.forRoot(routes, { enableTracing: false, initialNavigation: 'enabled', relativeLinkResolution: 'legacy',  scrollPositionRestoration: 'enabled' }),
    FormsModule,
    ReactiveFormsModule,
    SightModalModule,
    TourModalModule,
    TourMapModule,
    SocialLoginModule,
    ModalModule.forRoot(),
    TourListModule,
    SightListModule,
    ToursModule,
    LoaderModule,
    SimilarToursModule,
    LoadingBarHttpClientModule,
    LightgalleryModule,
    TourSightseeingModule,
    MinifiedSightListModule,
    LoginModalModule,
    ErrorLogModule,
    NgSelectModule,

    TabsModule,
    NoDataBlockModule,
    ReviewsBlockModule,
    NgxGoogleAnalyticsModule.forRoot('G-1HDTVR9TJG'),
    NgxGoogleAnalyticsRouterModule
  ],
  providers: [
   /*   {  provide: ErrorHandler,
    useClass: RaygunErrorHandler} */
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
