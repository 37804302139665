<!-- Hero-->
<div class="page-wrapper">
    <!-- Hero -->
    <section class="container py-5 px-5 mt-5 mb-lg-3">
        <div class="row align-items-center mt-md-2">
            <div class="col-lg-7 order-lg-2 mb-lg-0 mb-4 pb-2 pb-lg-0"><img class="d-block mx-auto"
                    src="../../../assets/img/home/hero-img.jpg" width="746" alt="Hero image"></div>
            <div class="col-lg-5 order-lg-1 pe-lg-0">
                <h1 class="display-5 mb-4 me-lg-n5 text-lg-start text-center mb-4" i18n>Guide the world, <span
                        class="dropdown d-inline-block">
                        <a class="text-decoration-none" href="javascript:void(0)" role="button" 
                            aria-haspopup="true" aria-expanded="false"> get paid for it!</a>
    </span></h1>
                <p class="text-lg-start text-center mb-4 fs-lg" i18n>Are you a tour guide or an influencer? Turn your expertise into income with Wingman. Register now to upload your tours, share your passion for travel, and earn money while helping others explore the world.</p>
                <ul class="list-unstyled mb-4 mb-sm-5">
                    <li class="d-flex mb-2"><i class="fi-check-circle text-primary mt-1 me-2"></i><span><ng-container i18n>Create and sell guided city tours</ng-container></span></li>
                    <li class="d-flex mb-2"><i class="fi-check-circle text-primary mt-1 me-2"></i><span><ng-container i18n>Share your local expertise with the world</ng-container></span></li>
                    <li class="d-flex mb-0"><i class="fi-check-circle text-primary mt-1 me-2"></i><span><ng-container i18n>Earn money from your unique tours</ng-container></span></li>
                  </ul>
                  <button class="btn btn-primary d-none d-md-block" [routerLink]="['/register']" i18n>Start uploading tours</button>

            </div>
        </div>
    </section>
    <section class="container py-md-4 mt-md-3 mt-0 mb-lg-3">
      
       
        <!-- App CTA Knowledgebase -->
        <app-cta-knowledgebase></app-cta-knowledgebase>
    </section>

</div>