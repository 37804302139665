import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Injectable()
export class UtilsService {

  constructor() {}

  detectPlatformAndNavigateUserToStore(){
    var userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      window.open('https://apps.apple.com/us/app/wingman-city-guide/id6736835761', '_blank');
      return true;
    } else{
      window.open('https://play.google.com/store/apps/details?id=com.wingman.cityguide', '_blank');
    }

   
    return true;
  }

  calcCrow(lat1, lon1, lat2, lon2) 
  {
    let R = 6371; // km
    let dLat = this.toRad(lat2-lat1);
    let dLon = this.toRad(lon2-lon1);
    lat1 = this.toRad(lat1);
    lat2 = this.toRad(lat2);

    let a = Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      let d = R * c;
    return Math.round(d*1000);
  }

   toRad(Value) 
  {
      return Value * Math.PI / 180;
  }

  convertToKm(m){
    if(m<1000){
        return m + ' m';
    } else{
        return (m/1000).toFixed(1) + ' km';
    }
  }

  convertToHours(minutes){
    if(minutes<60){
        return minutes + $localize ` minute(s)`;
    }
    let hours = Math.floor(minutes / 60);          
    let mins = minutes % 60;
    return hours + $localize ` hour(s) ` + mins + $localize ` minute(s)`;
  }

  scrollTo(querySelector, negativeOffset){
    window.scrollTo({
      top:  document.querySelector(querySelector).offsetTop - negativeOffset,
      behavior: 'smooth',
    })
  }
}
