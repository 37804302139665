<section class="container mb-5 mb-md-0" >
  <div class="sfs-cta-container-bg shadow-sm d-flex align-items-center p-5" [class]="class">
    <div class="row">
      <div class="col-12 col-md-6">
        <h2 class="h1 text-white">Explore The World 
          <a class="text-decoration-none" href="javascript:void(0)" role="button" 
                            aria-haspopup="true" aria-expanded="false">Like a Local!</a></h2>
                            <p class="text-white">Uncover 55+ cities through 1,000+ engaging audio tours.
                              Get authentic local insights and hidden gems in every destination!</p>
        <div class="d-flex">
          <a href="https://apps.apple.com/us/app/wingman-city-guide/id6736835761" class="me-3" target="_blank">
            <img src="../../../../assets/img/platform/appstore.gif" width="300">
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.wingman.cityguide" target="_blank">
            <img src="../../../../assets/img/platform/googleplay.gif" width="300">
          </a>
        </div>
      </div>
      
    </div>
  </div>
  
</section>
  
  
  