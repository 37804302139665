import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Route, RouterModule } from '@angular/router';
import { NgtUniversalModule } from '@ng-toolkit/universal';

import { CoreModule } from '@core/core.module';
import { HomeComponent } from './home.component';

import { NoDataBlockModule } from '../../modules/no-data-block/no-data-block.module';
import { SightListModule } from '../../modules/sight-list/sight-list.module';
import { CtaMobileAppModule } from '../../modules/landing/cta-mobile-app/cta-mobile-app.module';
import { CtaKnowledgebaseModule } from '../../modules/landing/cta-knowledgebase/cta-knowledgebase.module';

import { CtaFeaturedModule } from '../../modules/landing/cta-featured/cta-featured.module';

import { SearchListModule } from 'src/app/modules/search-list/search-list.module';
import { LandingCategoriesModule } from 'src/app/modules/landing-categories/landing-categories.module';
import { LandingCitiesModule } from 'src/app/modules/landing-cities/landing-cities.module';




@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    NgtUniversalModule,
    SearchListModule,
    LandingCategoriesModule,
    CtaFeaturedModule,
    NoDataBlockModule,
    CtaMobileAppModule,
    RouterModule,
    CtaKnowledgebaseModule,
    SightListModule,
    LandingCitiesModule
  ],
  exports: [RouterModule]
})
export class HomeModule {}
