import { isPlatformBrowser } from "@angular/common";
import {
  AfterViewInit,
  APP_ID,
  Component,
  Inject,
  PLATFORM_ID,
} from "@angular/core";
import { setTheme } from "ngx-bootstrap/utils";

import {
  Event as RouterEvent,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { ApiService, AppService } from "@services";
import { take } from "rxjs/operators";
import { ConfigService } from "@core/services/config.service";

// Quick hack, because importing jquery with bootstrap makes issues with SSR, we use this alternative
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let $: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements AfterViewInit {
  constructor(
    private router: Router,
    private loadingBarService: LoadingBarService,
    public appService: AppService,
    private apiService: ApiService,
    private configService: ConfigService,
    @Inject(PLATFORM_ID) private platformId: unknown,
    @Inject(APP_ID) private appId: string
  ) {
    setTheme("bs5");

    if (isPlatformBrowser(platformId)) {
      this.router.events.subscribe(this.navigationInterceptor.bind(this));
    }
  }
  

  ngAfterViewInit(): void {
    this.getConfig();
    //this.initGoogleAuth();
  }

  /**
   * Gets the config from the server
   */
  
  getConfig() {
    this.configService.getConfig();
  }

  private isAppLoading = false;

  get isLoading(): boolean {
    return this.isAppLoading;
  }
  set isLoading(newValue: boolean) {
    if (newValue) {
      this.loadingBarService.start();
    } else {
      this.loadingBarService.complete();
    }

    this.isAppLoading = newValue;
  }

  /**
   * Navigation interceptor
   * @param event navigation event
   */
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.isLoading = true;
    }
    if (event instanceof NavigationEnd) {
      this.isLoading = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.isLoading = false;
    }
    if (event instanceof NavigationError) {
      this.isLoading = false;
    }
  }
}
